/* Nuka Carousel */
.slider-control-bottomcenter {
  margin-bottom: -26px;
}

.border-radius-10 {
  border-radius: 0.625rem;
}

/* NO EMPTY P TAGS */
p:empty {
  display: none;
}

.feature-image {
  border-radius: 20px;
}

.desktop-home-hero-carousel,
.desktop-home-hero-carousel > .slider-frame,
.desktop-home-hero-carousel > .slider-frame > .slider-list,
.desktop-home-hero-carousel > .slider-frame > .slider-list > .slider-slide {
  position: relative;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

/* Special handling for ie11 */
#ie11-alert_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

#ie11-alert {
  margin-top: 2%;
  text-align: center;
  width: 100%;
  background-color: #fff;
  padding: 2rem;
  max-width: 550px;
  max-height: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
}

#ie11-alert_close {
  background-color: #005eab;
  padding: 1rem 2rem;
  min-height: 48px;
  width: auto;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

#ie11-alert_content-container {
  margin: 0.5rem 1.5rem;
  font-weight: 400;
  font-size: 1rem;
  text-transform: none;
}

#ie11-alert_close-container {
  margin-top: 1rem;
}
